
require.register("sharp11/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "sharp11");
  (function() {
    module.exports.note = require('./lib/note');
module.exports.chord = require('./lib/chord');
module.exports.scale = require('./lib/scale');
module.exports.midi = require('./lib/midi');
module.exports.duration = require('./lib/duration');
module.exports.interval = require('./lib/interval');
module.exports.chart = require('./lib/chart');
module.exports.corpus = require('./lib/corpus');
module.exports.mehegan = require('./lib/mehegan');
  })();
});