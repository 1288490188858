
require.register("mod-loop/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "mod-loop");
  (function() {
    module.exports = loop

function loop(value, divisor) {
  var n = value % divisor;
  return n < 0 ? (divisor + n) : n
}
  })();
});